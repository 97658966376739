import {
  getAuth,
  User,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  validatePassword,
  PasswordValidationStatus,
} from 'firebase/auth';
import { firebaseService } from '@when-fertility/shared/domain/common';

type OnAuthStateChangedParam = (firebaseUser: User | null) => void;
type OnIsTokenChangedParam = () => void;

class AuthService {
  getFirebaseUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const unsubscribe = getAuth(firebaseService).onAuthStateChanged((firebaseUser) => {
        resolve(firebaseUser);
        unsubscribe();
      });
    });
  }

  reloadFirebaseUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      getAuth(firebaseService)
        .currentUser?.reload()
        .then(() => this.getFirebaseUser())
        .then((firebaseUser) => (firebaseUser ? resolve(firebaseUser) : reject()))
        .catch(reject);
    });
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      getAuth(firebaseService).currentUser?.getIdToken(true).then(resolve).catch(reject);
    });
  }

  validatePassword(password: string): Promise<PasswordValidationStatus & { errorMessages: string[] }> {
    return new Promise((resolve, reject) => {
      validatePassword(getAuth(firebaseService), password)
        .then((validationResult) => {
          const errors = [];
          if (!validationResult.isValid) {
            if (!validationResult.meetsMinPasswordLength) {
              errors.push('Password must meet minimum length requirement');
            }
            if (!validationResult.containsUppercaseLetter) {
              errors.push('Password must contain at least one uppercase letter');
            }
            if (!validationResult.containsNumericCharacter) {
              errors.push('Password must contain at least one number');
            }
            if (!validationResult.containsNonAlphanumericCharacter) {
              errors.push('Password must contain at least one special character');
            }
          }
          resolve({ ...validationResult, errorMessages: errors });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  onAuthStateChanged(callback: OnAuthStateChangedParam) {
    return getAuth(firebaseService).onAuthStateChanged(callback);
  }

  onIdTokenChanged(callback: OnIsTokenChangedParam) {
    return getAuth(firebaseService).onIdTokenChanged(callback);
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      getAuth(firebaseService)
        .setPersistence(browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(getAuth(), email, password))
        .then(() => this.getFirebaseUser())
        .then((firebaseUser) => (firebaseUser ? resolve(firebaseUser) : reject()))
        .catch(reject);
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      signOut(getAuth(firebaseService)).then(resolve).catch(reject);
    });
  }

  sendResetPasswordEmail(email: string) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(getAuth(firebaseService), email).then(resolve).catch(reject);
    });
  }
}

export const authService = new AuthService();
