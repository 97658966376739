import { GqlAddressInput, useSignUpMutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile';
import { useAuth } from '..';
import { Button, Page, Icon, Modal } from '@when-fertility/shared/domain/common';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { PlacesAutocompleteComponent } from '../components/places-autocomplete.component';
import TermsAndConditionsComponent from '../components/terms-and-conditions.component';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { ApolloError } from '@apollo/client';
import { authService } from '../../auth';

// Extend the Window interface to include zxcvbn
declare global {
  interface Window {
    zxcvbn: (password: string) => any;
  }
}

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  address: GqlAddressInput;
  agreeToTermsAndConditions: boolean;
}

export const INPUT_CLASSES =
  'block placeholder-charcoal-40 relative rounded-full w-full px-4 leading-9 border focus:outline-none min-h-input border-silver-100 focus:border-charcoal-100';
const loadScript = (src: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(script);
  });
};
export const RegisterPage = () => {
  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    watch,
    setValue,
  } = useForm<FormData>();
  const { logInAndRedirect, logout } = useAuth();
  const [formError, setFormError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginAndRedirecting, setIsLoginAndRedirecting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState();

  const turnstileRef = React.useRef();

  const password = watch('password', '');
  const agreeToTermsAndConditions = watch('agreeToTermsAndConditions', false);
  const phoneOnChange = (value: string) => {
    setValue('phone', value);
  };

  const [signUp, { loading: isLoading }] = useSignUpMutation({
    variables: {
      input: {
        ...getValues(),
        cfTurnstileResponse: turnstileToken,
      },
    },
    onCompleted: async () => {
      setIsLoginAndRedirecting(true);
      await logInAndRedirect({ email: getValues().email, password: getValues().password });
      setIsLoginAndRedirecting(false);
    },
    onError: async (error: ApolloError) => {
      setFormError(error.message);
    },
  });

  useEffect(() => {
    (async () => {
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout();
      loadScript('https://cdn.jsdelivr.net/npm/zxcvbn@4.4.2/dist/zxcvbn.js')
        .then(() => {
          // zxcvbn is loaded and available to use
        })
        .catch((error) => {
          console.error(error);
        });
    })();

    (async () => {
      await turnstileRef.current
        ?.getResponsePromise()
        .then((token) => {
          setTurnstileToken(token);
        })
        .catch((error) => {
          console.error(error);
        });
    })();
  }, []);

  const onSubmit = async () => {
    const validationResult = await authService.validatePassword(password);
    if (!validationResult.isValid && validationResult.errorMessages.length > 0) {
      setFormError(validationResult.errorMessages.join('. '));
      return;
    }
    if (password.length < 6) {
      setFormError('Password must be at least 6 characters.');
      return;
    }
    if (!agreeToTermsAndConditions) {
      setFormError('You must agree to When’s terms and conditions');
      return;
    }
    setFormError('');
    await signUp();
  };

  const handleContinue = async () => {
    let isValid = await trigger();

    const validationResult = await authService.validatePassword(password);
    if (!validationResult.isValid && validationResult.errorMessages.length > 0) {
      isValid = false;
      setFormError(validationResult.errorMessages.join('. '));
    }

    if (!isValid) {
      return;
    } else {
      setFormError('');
    }

    setIsModalOpen(true);
  };

  const getPasswordScore = useCallback(
    (password: string) => {
      if (window.zxcvbn && password) {
        const result = window.zxcvbn(password);
        const score = result.score; // zxcvbn returns a score from 0 to 4 indicating password strength
        const feedback = result.feedback;
        return { score, feedback, color: getProgressColor(score) };
      }
    },
    [password]
  );

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getProgressColor = (score: number) => {
    switch (score) {
      case 0:
        return 'bg-red-500';
      case 1:
        return 'bg-orange-500';
      case 2:
        return 'bg-yellow-500';
      case 3:
        return 'bg-blue-500';
      case 4:
        return 'bg-green-500';
      default:
        return 'bg-red-500';
    }
  };
  const validatePhoneNumber = (value?: string) => {
    if (!value) {
      return 'Phone is required';
    } else if (!isValidPhoneNumber(value)) {
      return 'Invalid phone number';
    }
  };

  const setAddressValue = (value: GqlAddressInput) => {
    setValue('address', value);
  };

  return (
    <Page isCentred variant="medium" className="bg-blob-outline bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]">
      <div className="w-80 sm:w-100">
        <div>
          <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">Create account</h2>
        </div>
        <div className="flex flex-col items-center mt-3">
          <div className="mb-2 text-xs">
            <Link to="/login" className="pl-1 underline">
              Already have an account? Login here
            </Link>
          </div>
        </div>
        <form className="mt-5">
          <div className="flex flex-col justify-between md:flex-row gap-3 mb-3">
            <div className="flex flex-col">
              <input
                className={cn(INPUT_CLASSES, 'w-full')}
                type="text"
                disabled={isLoading}
                placeholder="First name"
                {...register('firstName', { required: 'First Name is required' })}
              />
              {errors.firstName && <div className="text-red-600 text-center text-sm mt-1">{errors.firstName.message}</div>}
            </div>
            <div className="flex flex-col">
              <input
                className={cn(INPUT_CLASSES, 'w-full')}
                type="text"
                disabled={isLoading}
                placeholder="Last name"
                {...register('lastName', { required: 'Last Name is required' })}
              />
              {errors.lastName && <div className="text-red-600 text-center text-sm mt-1">{errors.lastName.message}</div>}
            </div>
          </div>
          <div className="relative mb-3">
            <PhoneInput
              key="TEST-PHONE-INPUT"
              defaultCountry={'AU'}
              withCountryCallingCode={false}
              country={'AU'}
              placeholder="Phone number"
              disabled={isLoading}
              className={INPUT_CLASSES}
              onChange={phoneOnChange}
            />
            <input className={'hidden'} {...register('phone', { required: 'Phone is required', validate: validatePhoneNumber })} />
            {errors.phone && <div className="text-red-600 text-center text-sm mt-1">{errors.phone.message}</div>}
          </div>
          <PlacesAutocompleteComponent register={register} disabled={isLoading} setFormValue={setAddressValue} />
          {errors.address && <div className="text-red-600 text-center text-sm mt-1">{errors.address.message}</div>}
          <div className="mb-3">
            <input
              className={INPUT_CLASSES}
              type="email"
              disabled={isLoading}
              autoComplete="current-password"
              placeholder="Email address"
              {...register('email', { required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' } })}
            />
            {errors.email && <div className="text-red-600 text-center text-sm mt-1">{errors.email.message}</div>}
          </div>
          <div className="mb-3 relative">
            <input
              type={showPassword ? 'text' : 'password'}
              disabled={isLoading}
              autoComplete="current-password"
              className={INPUT_CLASSES}
              placeholder="Password"
              {...register('password', { required: 'Password is required' })}
            />
            <button
              type="button"
              onClick={handleTogglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
            >
              {showPassword ? (
                <Icon icon="cross" className="text-gray-400" height={15} width={24} />
              ) : (
                <Icon icon="eye-open" className="text-gray-400" height={20} width={30} />
              )}
            </button>
          </div>
          {errors.password && <div className="text-red-600 text-center text-sm mt-1">{errors.password.message}</div>}
          {getPasswordScore(password) && (
            <>
              <div className="w-full mb-3 bg-gray-200 rounded-lg overflow-hidden">
                <div
                  className={cn(`h-2 ${getPasswordScore(password)?.color}`, { hidden: !getPasswordScore(password) })}
                  style={{ width: `${(getPasswordScore(password)?.score + 1) * 20}%` }} // zxcvbn score is from 0 to 4, so we multiply by 20 to get percentage
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <div className="flex flex-row">
                <span className="mb-3 w-full text-sm text-stone-600 max-w-[400px] overflow-wrap-normal">
                  {getPasswordScore(password)?.feedback?.suggestions?.join(' ')}
                </span>
              </div>
            </>
          )}
          <div className="mb-3 flex justify-center">
            <Turnstile ref={turnstileRef} siteKey={process.env.CF_TURNSTILE_SITE_KEY || '1x00000000000000000000AA'} />
          </div>

          {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
          <Button
            isDisabled={isLoading || !turnstileToken}
            onClick={() => {
              handleContinue();
            }}
            className="w-full"
          >
            Continue
          </Button>
          {/*Terms And Conditions*/}
          <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <TermsAndConditionsComponent setHasRead={setHasRead}>
              <div className={'py-3'}>
                <input
                  className={'disabled:bg-red-300 pt-3'}
                  type="checkbox"
                  disabled={!hasRead}
                  {...register('agreeToTermsAndConditions')}
                  checked={getValues().agreeToTermsAndConditions}
                  onSelect={(event: any) => {
                    setValue('agreeToTermsAndConditions', event.target.checked);
                    trigger();
                  }}
                />
                <label
                  className="pl-3"
                  htmlFor="agree"
                  onClick={() => {
                    if (hasRead) {
                      setValue('agreeToTermsAndConditions', true);
                      trigger();
                    }
                  }}
                >
                  {!hasRead
                    ? 'Please scroll to the bottom before you can tick accept and proceed'
                    : "I have read and agreed to WHEN's Terms and Conditions and Privacy Policy"}
                </label>
              </div>
              {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
              <Button
                isDisabled={!getValues().agreeToTermsAndConditions || isLoading || isLoginAndRedirecting}
                className="w-full mb-12"
                onClick={(event: any) => {
                  event.preventDefault();
                  onSubmit();
                }}
              >
                {isLoading ? 'Creating' : 'Create Account'}
              </Button>
            </TermsAndConditionsComponent>
          </Modal>
        </form>
      </div>
    </Page>
  );
};

RegisterPage.mutation = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      email
      firstName
      lastName
    }
  }
`;
