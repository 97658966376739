import { Button, Icon, Input } from '@when-fertility/shared/domain/common';
import { useSettingsSummaryItemUpdateUserMutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common/components';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input/input';
import { INPUT_CLASSES, authService } from '../../auth';
type SettingsItem = 'firstName' | 'lastName' | 'email' | 'password' | 'phone' | 'address';

type Props = {
  settingsItem: SettingsItem;
  defaultValue?: string;
  showDisclaimer?: boolean;
  onSuccess?: () => void;
};

const settingsLabelMap: Record<SettingsItem, string> = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  password: 'Password',
  phone: 'Phone',
  address: 'Address',
};

const FormInput = ({
  settingsItem,
  formValue,
  setFormValue,
}: {
  settingsItem: string;
  formValue: string;
  setFormValue: Dispatch<SetStateAction<string>>;
}) => {
  if (settingsItem === 'phone') {
    return (
      <PhoneInput
        key="TEST-PHONE-INPUT"
        defaultCountry={'AU'}
        withCountryCallingCode={false}
        country={'AU'}
        placeholder="Phone number"
        className={INPUT_CLASSES}
        onChange={(value) => setFormValue(value || '')}
      />
    );
  }
  return (
    <Input
      defaultValue={settingsItem === 'password' ? '' : formValue}
      type={settingsItem === 'password' || settingsItem === 'confirmPassword' ? 'password' : 'text'}
      placeholder={settingsItem === 'password' ? '' : formValue}
      onChange={({ target }) => setFormValue(target.value)}
    />
  );
};

export const SettingsSummaryItem = ({ settingsItem, defaultValue, showDisclaimer = false, onSuccess }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formError, setFormError] = useState('');
  const [formValue, setFormValue] = useState('');
  const [confirmValue, setConfirmValue] = useState('');

  const handleClickEdit = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!defaultValue || formValue) {
      return;
    }
    setFormValue(defaultValue);
  }, [defaultValue]);

  const [updateCurrentUser, { loading: isLoading }] = useSettingsSummaryItemUpdateUserMutation({
    variables: {
      input: {
        ...(settingsItem === 'firstName' && { firstName: formValue }),
        ...(settingsItem === 'lastName' && { lastName: formValue }),
        ...(settingsItem === 'email' && { email: formValue }),
        ...(settingsItem === 'password' && { password: formValue }),
        ...(settingsItem === 'phone' && { phone: formValue }),
      },
    },
    onCompleted: async () => {
      setIsModalOpen(false);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: async (error) => {
      setFormError(error.message);
    },
  });

  const handleSave = async (event: any) => {
    event.preventDefault();
    setFormError('');
    if (settingsItem === 'password' && formValue.length < 6) {
      setFormError('Password must be at least 6 characters.');
      return;
    }
    if (settingsItem === 'phone' && !isValidPhoneNumber(formValue)) {
      setFormError('Phone number is invalid ');
      return;
    }

    if (settingsItem === 'password') {
      // check against policy
      const validationResult = await authService.validatePassword(formValue);

      if (!validationResult.isValid && validationResult.errorMessages.length > 0) {
        setFormError(validationResult.errorMessages.join('. '));
        return;
      }

      // authenticate with firebase again to ensure password is is valid
      const user = await authService.getFirebaseUser();
      if (user && user.email) {
        let result;
        try {
          result = await authService.signInWithEmailAndPassword(user.email, confirmValue);
        } catch (error) {
          console.error(error);
        }
        if (user?.uid !== result?.uid) {
          setFormError('Current password is incorrect.');
          return;
        }
      } else {
        setFormError('Please sign out and try again.');
        return;
      }
    }

    await updateCurrentUser();
  };

  const handleCancel = () => {
    setFormValue(defaultValue || '');
    setFormError('');
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="md:hidden flex justify-between items-center border-b border-silver-100 last:border-none p-4">
        <div className="w-1/2">
          <div className="text-charcoal-60">{settingsLabelMap[settingsItem]}</div>
          <div className="mt-1 text-charcoal-100">
            {settingsItem !== 'phone' && formValue}
            {settingsItem === 'phone' && formatPhoneNumber(formValue)}
          </div>
        </div>
        <div>
          <Button variant="secondary" className="flex items-center" onClick={() => handleClickEdit()}>
            <Icon icon="pencil" height={14} className="mr-2" />
            Edit
          </Button>
        </div>
      </div>
      <div className="hidden md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
        <div className="w-1/4 text-charcoal-60">{settingsLabelMap[settingsItem]}</div>
        <div className="w-3/4 flex justify-between items-center ml-12 text-charcoal-100">
          {settingsItem !== 'phone' && formValue}
          {settingsItem === 'phone' && formatPhoneNumber(formValue)}
          <Button className="flex items-center" variant="secondary" onClick={() => handleClickEdit()}>
            <Icon icon="pencil" height={14} className="mr-2" />
            Edit
          </Button>
        </div>
      </div>

      <FullScreenModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="mt-6">
          <div className="w-full md:w-96">
            {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
            <div className="text-md mb-4">Update your {settingsLabelMap[settingsItem]}</div>
            <div className="text-md mb-1 text-charcoal-60">Current {settingsLabelMap[settingsItem]}</div>
            <div className="text-md mb-4">
              {(() => {
                if (settingsItem === 'phone') {
                  return formatPhoneNumber(defaultValue || '');
                } else if (settingsItem === 'password') {
                  return <FormInput formValue="" setFormValue={setConfirmValue} settingsItem="confirmPassword" />;
                } else {
                  return defaultValue;
                }
              })()}
            </div>
            <div className="text-md mb-2 text-charcoal-60">New {settingsLabelMap[settingsItem]}</div>
            <div className="h-full flex flex-col justify-between">
              <div className="md:h-24">
                <FormInput formValue={formValue} setFormValue={setFormValue} settingsItem={settingsItem} />
              </div>
            </div>
          </div>
          {showDisclaimer && (
            <div className="w-full md:w-96 md:mt-0 mt-12 text-xs text-center">You’ll have to log in again after saving these changes</div>
          )}
          <div className="flex flex-col w-full mt-12">
            <Button onClick={handleSave} isDisabled={isLoading} className="">
              Save
            </Button>
            <Button onClick={handleCancel} className="mt-2" variant="secondary">
              Cancel
            </Button>
          </div>
        </div>
      </FullScreenModalComponent>
    </>
  );
};

SettingsSummaryItem.mutation = gql`
  mutation SettingsSummaryItemUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
